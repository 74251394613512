import { Controller } from '@hotwired/stimulus'

/**
 * ハンバーガーメニューの開閉を制御する Controller
 */
export class HeaderController extends Controller {
  /**
   * メニューをクリックした時のイベントを設定する
   */
  connect () {
    const navList = this.navlistTarget.querySelectorAll('.header__nav-item')
    this.navListClickRedy(navList)
  }

  /**
   * ハンバーガーメニューの開閉を制御するclassを付け替える
   */
  menuToggle () {
    this.humbergerTarget.classList.toggle('header__humberger--active')
    this.navTarget.classList.toggle('header__nav--active')
    this.navTarget.classList.toggle('header__nav_inactive')
  }

  /**
   * メニューを選択した時にclassを付け替えるためのイベントを準備
   *
   * @param {Array} navList
   */
  navListClickRedy (navList) {
    navList.forEach((element) => {
      element.addEventListener('click', () => {
        this.humbergerTarget.classList.toggle('header__humberger--active')
        this.navTarget.classList.toggle('header__nav--active')
        this.navTarget.classList.toggle('header__nav_inactive')
      })
    })
  }

  /**
   * スクロールされた時にヘッダーの背景色を変更する
   */
  onScroll () {
    if (window.scrollY > 200) {
      this.headerTarget.classList.add('header--scroll')
    } else {
      this.headerTarget.classList.remove('header--scroll')
    }
  }
}

HeaderController.targets = ['header', 'humberger', 'nav', 'navlist']
