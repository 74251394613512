import { Controller } from '@hotwired/stimulus'

const id = '17841448466641629'
const limit = '10'
const token = 'EAArZBLAlw8O8BAJgXQ5k24emMOebe5OCGkfAkPcRTu9fHPmMgkOwZC8uxYZAfHZAkS1W1pc3Qv3iqydqTvpZCtDFGOUR6bySbLYOhPhgOogDB0ZBEafrXzUiCi5hN8VXhN5AB9E8MUfkjJCOOlkh2pGrKaCZBO3Siv0abLmTYwSeOWsvaNmAKusmuLqEZAI6cWYZD'

/**
 * インスタグラムの投稿を表示するためのController
 */
export class InstagramController extends Controller {
  /**
   * hoge
   */
  async connect () {
    const res = await this.fetch()
    this.createElement(res)
  }

  /**
   * @returns {object}
   */
  async fetch () {
    console.log(this.endpoint)
    const res = await fetch(this.endpoint)
    return res.json()
  }

  /**
   * @returns {string}
   */
  get endpoint () {
    return `https://graph.facebook.com/v6.0/${id}?fields=name%2Cmedia.limit(${limit})%7Bcaption%2Cmedia_url%2Cthumbnail_url%2Cpermalink%7D&access_token=${token}`
  }

  /**
   * @param {object} res
   */
  createElement (res) {
    res.media.data.forEach((data) => {
      const linkElement = document.createElement('a')
      linkElement.href = data.permalink
      linkElement.target = 'blank'
      const imgElement = document.createElement('img')
      imgElement.src = data.media_url
      imgElement.width = '190'
      imgElement.height = '190'
      imgElement.loading = 'lazy'
      linkElement.appendChild(imgElement)
      this.containerTarget.appendChild(linkElement)
    })
  }
}

InstagramController.targets = ['container']
