import { Application } from '@hotwired/stimulus'
import { HeaderController } from './controllers/header_controller'
import { InstagramController } from './controllers/instagram_controller'

const app = Application.start()
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  app.debug = true
}

app.register('header', HeaderController)
app.register('instagram', InstagramController)
